<template>
  <div>
    <CampusHeader
        @start-creating="startCreate()"
        :show-mobile="false"
        :can-create="false"
        :activeTab="activeTab"
        :manage-campuses="true"
        :class-group-subject-id="selectedSubject?selectedSubject.id:null"
        :manage-class-groups="true"
        :can-all-class-groups="true"
        :can-select-class-groups="activeTab===1"
        :can-select="activeTab===0"
        :can-select-year="activeTab===0"
        :create-text="createText"
        @change-tab="changeTab"
        :header-text="headerText"
        :header_tabs="header_tabs"
        :can-all="true"
    ></CampusHeader>
    <section class="section pt-4">
      <b-tabs expanded multiline  :destroy-on-hide="true" class="hidden-tabs" v-model="activeTab">
        <b-tab-item v-if="useTab('subjects')">
          <div class="box columns">
            <div class="column is-12">
              <SubjectsIndex
                  :action-text="'Select'"
                  @selected="option => handleSubjectSelect(option)"
              >
              </SubjectsIndex>
            </div>
          </div>
        </b-tab-item>
        <b-tab-item v-if="useTab('objectives')">
          <div class="box columns">
            <div class="column is-12">
              <ProgressTable
                  :default-class-group-id="this.$store.state.campus.selected_class_group_id"
                  :default-subject-id="selectedSubject.id"
              >

              </ProgressTable>
            </div>
          </div>
        </b-tab-item>


      </b-tabs>
    </section>
  </div>
</template>

<script>

import CampusHeader from "@/components/panelled-dash/CampusHeader";
import ReportForm from "@/components/reports/ReportForm";
import SubjectsIndex from "@/components/lessonPlans/SubjectsIndex";
import ReportSubjectForm from "@/components/reports/ReportSubjectForm";
import Report from "@/models/Report";
import ReportSubject from "@/models/ReportSubject";
import ProgressTable from "@/components/progressTracker/ProgressTable";
// import Enrolment from "@/models/Enrolment";

export default {
  name: "ProgressTracker",
  components: {
    CampusHeader,
    SubjectsIndex,
    ProgressTable

  },
  data() {
    return {
      activeTabs: ['subjects'],
      loaded: false,
      activeTab: 0,
      selectedSubject: null,
      selectedAssessment: null,
      search: null,
      loadingFilter: false,
      userDataHold: null,
      meta: Object,
      page: 1,
      limit: 20,
      loadingData: false,
      searchHold: null,
    };
  },


  methods: {
    handleSubjectSelect(subject) {
      this.selectedSubject = subject
      this.activateTab('objectives')
      this.changeTab(1)
    },
    startCreate() {
      if (this.activeTab === 0) {
        this.startCreateReport()
      }
      if (this.activeTab === 1) {
        this.startCreateReportSubject()
      }
    },
    startCreateReportSubject() {
      this.$buefy.modal.open({
        parent: this,
        props: {
          campus_id: this.$store.state.campus.selected_campus_id,
          edit: false,
          inModal: true,
          report: this.selectedReport
        },
        component: ReportSubjectForm,
        fullScreen: false,
        trapFocus: true,
        canCancel: ['outside', 'x']
      });
    }, startCreateReport() {
      this.$buefy.modal.open({
        parent: this,
        props: {
          campus_id: this.$store.state.campus.selected_campus_id,
          edit: false,
          manageTerms: true,
          inModal: true,
        },
        component: ReportForm,
        fullScreen: false,
        trapFocus: true,
        canCancel: ['outside', 'x']
      });
    },
    handleReportSubjectEdit(reportSubject) {
      this.$buefy.modal.open({
        parent: this,
        props: {
          campus_id: this.$store.state.campus.selected_campus_id,
          edit: true,
          report: this.selectedReport,
          reportSubject: reportSubject,
          inModal: true,
        },
        component: ReportSubjectForm,
        fullScreen: false,
        trapFocus: true,
        canCancel: ['outside', 'x']
      });
    }, handleReportEdit(report) {
      this.$buefy.modal.open({
        parent: this,
        props: {
          campus_id: this.$store.state.campus.selected_campus_id,
          edit: true,
          report: report,
          manageTerms: true,
          inModal: true,
        },
        component: ReportForm,
        fullScreen: false,
        trapFocus: true,
        canCancel: ['outside', 'x']
      });
    },
    activateTab(tabName) {
      if (!this.activeTabs.includes(tabName)) {
        this.activeTabs.push(tabName)
      }
    },
    handleReportSelect(report) {
      this.$store.dispatch('loader/show')
      this.activateTab('subjects')
      this.activateTab('students')

      Report.FetchById(report.id, ['terms']).then(() => {
        this.selectedReport = Report.query().where('id', report.id).first()
        this.$nextTick(() => {
          this.changeTab(1)

        })
      }).catch(err => {
        this.handleError(err)
      }).finally(() => {
        this.$store.dispatch('loader/hide')
      })

    }, handleReportSubjectSelect(subject) {
      this.$store.dispatch('loader/show')

      this.activateTab('mark-groups')


      ReportSubject.FetchById(this.selectedReport.id, subject.id).then(() => {
        this.selectedSubject = ReportSubject.query().where('id', subject.id).first()
        this.$nextTick(() => {
          this.changeTab(2)
        })
      }).catch(err => {
        this.handleError(err)
      }).finally(() => {
        this.$store.dispatch('loader/hide')
      })
    },
    handleAssessmentSelect(assessment) {

      this.selectedAssessment = assessment
      this.$nextTick(() => {
        this.changeTab(3)

      })
    },
    changeTab(index) {
      this.$nextTick(() => {
        this.activeTab = index;

      })
    },

    useTab(role) {
      return this.tabs.some((tab) => tab.role === role);
    },

  },

  computed: {
    tabs() {
      let tabs = []
      if (this.activeTabs.includes('subjects')) {
        tabs.push({name: 'Subjects', role: 'subjects'})
      }
      if (this.activeTabs.includes('objectives')) {
        tabs.push({name: 'Objectives Table', role: 'objectives'})
      }


      return tabs
    },
    header_tabs() {
      let tabs = []
      if (this.activeTabs.includes('subjects')) {
        tabs.push({title: 'Subjects'})
      }
      if (this.activeTabs.includes('objectives')) {
        tabs.push({title: 'Objectives Table'})
      }


      return tabs
    },
    createText() {

      if (this.activeTab === 1) {
        return 'Add Subject'
      }

      return ''
    },
    canCreate() {
      if (this.activeTab === 0 && this.$store.getters['entities/user-permissions/find']('create reports')) {
        return true
      }
      if (this.activeTab === 1 && this.$store.getters['entities/user-permissions/find']('create report-subjects') && (this.selectedReport.status_id < 7 || !!this.$store.getters['entities/user-permissions/find']('edit complete reports'))) {
        return true
      }
      // if (this.activeTab === 2 && this.$store.getters['entities/user-permissions/find']('create assessments')) {
      //   return true
      // }
      return false
    },
    headerText() {
      // if (this.selectedReport === null) {
      return 'Progress Tracker'
      // }
      // return `${this.selectedReport.name}`
    }


  },
  mounted() {

  },
};
</script>
