<template>
  <form @submit.prevent="submit">
    <div :class="{'modal-card':inModal}" style="width: auto;">
      <header v-if="inModal" class="modal-card-head">
        <p class="modal-card-title">{{ edit === false ? 'Create a new' : 'Edit' }} Report Subject</p>
      </header>
      <section :class="{'modal-card-body':inModal}">
        <b-field v-if="!edit" expanded label="Subject">
          <SubjectsFilter
              @selected="option => selectSubject(option)"
              :type="'autocomplete'"
              :campus_id="campus_id"
          ></SubjectsFilter>
        </b-field>
        <!--        <b-field expanded label="Educator Name">-->
        <!--          <b-input required expanded placeholder="Educator Name" v-model="reportSubjectObject.educator_name"></b-input>-->
        <!--        </b-field>-->
        <!--        <b-field v-if="reportSubjectObject.id" expanded label="Teacher">-->
        <!--          <b-select expanded required v-model="reportSubjectObject.teacher_id">-->
        <!--            <option v-for="teacher in teachers" :key="teacher.id" :value="teacher.id">-->
        <!--              {{ teacher.first_name }} {{ teacher.last_name }}-->
        <!--            </option>-->

        <!--          </b-select>-->

        <!--        </b-field>-->
        <b-field style="overflow: hidden" label="Description">
          <ckeditor v-if="loaded" style="overflow: hidden"
                    :editor="editor"
                    v-model="reportSubjectObject.report_description"
                    :config="editorConfig"
          ></ckeditor>
        </b-field>
        <b-field expanded label="Display Type">
          <b-select placeholder="Display Type" expanded required v-model="reportSubjectObject.display_type_id">
            <option v-for="type in $store.state.reports.subject_display_type" :key="type.id" :value="type.id">
              {{ type.name }}
            </option>

          </b-select>

        </b-field>
        <b-field label="Educator Comment Required">
          <b-switch :true-value="1" :false-value="0" v-model="reportSubjectObject.requires_educator_comment">
            {{ reportSubjectObject.requires_educator_comment ? 'Yes' : 'No' }}
          </b-switch>
        </b-field>
        <b-field label="Educator Checkpoint Required">
          <b-switch :true-value="1" :false-value="0" v-model="reportSubjectObject.requires_educator_checkpoints">
            {{ reportSubjectObject.requires_educator_checkpoints ? 'Yes' : 'No' }}
          </b-switch>
        </b-field>
        <b-field expanded label="Educator Name">
          <b-select placeholder="Educator Name Display Type" expanded required
                    v-model="reportSubjectObject.educator_display_type_id">
            <option v-for="type in $store.state.reports.educator_display_type" :key="type.id" :value="type.id">
              {{ type.name }}
            </option>

          </b-select>

        </b-field>
        <div v-if="reportSubjectObject.requires_educator_checkpoints">
          <b-field label="Checkpoint Groups:"></b-field>
          <div v-for="(group,groupIndex) in reportSubjectObject.checkpoints" :key="groupIndex">
            <b-field label="Group Title" grouped>
              <b-field expanded>
                <b-input placeholder="Checkpoint Group Title" expanded v-model="group.title"></b-input>
              </b-field>
              <b-field>
                <b-button
                    type="is-danger"
                    :icon-right="$tc('icons.minus')"
                    @click="removeCheckpointGroup(groupIndex)"
                ></b-button>
              </b-field>
            </b-field>
            <b-field label="Checkpoints:"></b-field>
            <div v-for="(checkpoint,checkpointIndex) in group.options" :key="checkpointIndex">
              <b-field grouped label="Question">
                <b-field expanded>
                  <b-input expanded placeholder="Question" v-model="checkpoint.question"></b-input>
                </b-field>
                <b-field>
                  <b-button
                      type="is-danger"
                      :icon-right="$tc('icons.minus')"
                      @click="removeCheckpoint(groupIndex,checkpointIndex)"
                  ></b-button>
                </b-field>
              </b-field>
              <b-field label="Answers"></b-field>
              <div>
                <!--suppress JSUnusedLocalSymbols -->
                <b-field expanded grouped v-for="(answer,index) in checkpoint.answers" :key="index">
                  <b-field expanded>
                    <b-input expanded v-model="checkpoint.answers[index]" placeholder="Answer"></b-input>
                  </b-field>
                  <b-field>
                    <b-button
                        type="is-danger"
                        :icon-right="$tc('icons.minus')"
                        @click="removeAnswer(groupIndex,checkpointIndex,index)"
                    ></b-button>
                  </b-field>
                </b-field>
              </div>
              <b-field expanded position="is-right" class="my-4 has-text-right">
                <b-button type="is-primary" @click="addAnswer(groupIndex,checkpointIndex)">
                  Add Answer
                </b-button>
              </b-field>
            </div>
            <b-field>
              <b-button type="is-primary" @click="addCheckpoint(groupIndex)">Add Checkpoint</b-button>
            </b-field>
          </div>
          <hr>
          <b-field>
            <b-button type="is-primary" @click="addCheckpointGroup">Add Checkpoint Group</b-button>
          </b-field>
        </div>
      </section>
      <footer v-if="inModal" :class="{'modal-card-foot':inModal}">
        <b-button
            :disabled="edit?!$store.getters['entities/user-permissions/find']('edit report-subjects'):!$store.getters['entities/user-permissions/find']('create report-subjects')"
            native-type="submit" type="is-primary" :icon-right="$tc(`icons.${edit===false?'create':'edit'}`)">Save
        </b-button>
      </footer>
      <div class="pt-3" v-else>
        <b-field position="is-centered" grouped>
          <b-field position="is-centered">
            <b-button
                :disabled="edit?!$store.getters['entities/user-permissions/find']('edit report-subjects'):!$store.getters['entities/user-permissions/find']('create report-subjects')"
                native-type="submit" :icon-right="$tc(`icons.${edit===false?'create':'edit'}`)" type="is-primary">Save
            </b-button>
          </b-field>
        </b-field>
      </div>
    </div>
    <b-loading v-model="loading" :is-full-page="false" :can-cancel="false"></b-loading>
  </form>
</template>

<script>

import Term from "@/models/Term";
import SubjectsFilter from "@/components/panelled-dash/SubjectsFilter";
import ReportSubject from "@/models/ReportSubject";
import Subject from "@/models/Subject";
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import {editorConfig} from "@/editorConfig";

export default {
  name: "ReportSubjectForm",
  components: {SubjectsFilter},
  data() {
    return {
      loading: false,
      loaded:false,
      editor: ClassicEditor,
      editorConfig: {
        ...editorConfig,
        ...{placeholder: "The description to appear on reports."},
      },
      reportSubjectObject: {
        id: null,
        requires_educator_comment: 0,
        requires_educator_checkpoints: 0,
        educator_display_type_id: null,
        display_type_id: null,
        teacher_id: null,
        // educator_name: null,
        checkpoints: [],
        report_description: null,
      }
    }
  },

  computed: {
    teachers() {
      return Subject.query().whereId(this.reportSubjectObject.id).with('teachers').first().teachers
    },
    termsData() {
      if (this.manageTerms) {
        return Term.query().where('parent_id', null).where(term => {
          return new Date(term.start).getFullYear() === this.reportSubjectObject.year
        }).with('children').orderBy('start').get()
      }
      return this.terms
    },

  },
  methods: {
    addCheckpoint(groupIndex) {
      if (this.reportSubjectObject.checkpoints[groupIndex].options === null) {
        this.reportSubjectObject.checkpoints[groupIndex].options = []
      }
      this.reportSubjectObject.checkpoints[groupIndex].options.push({question: '', answers: ['']})
    }, addCheckpointGroup() {
      if (this.reportSubjectObject.checkpoints === null) {
        this.reportSubjectObject.checkpoints = []
      }
      this.reportSubjectObject.checkpoints.push({title: '', options: [{question: '', answers: ['']}]})
    },
    removeCheckpoint(groupIndex, removeIndex) {
      this.reportSubjectObject.checkpoints[groupIndex].options = this.reportSubjectObject.checkpoints[groupIndex].options.filter((checkpoint, index) => {
        return index !== removeIndex
      })
    },
    removeCheckpointGroup(groupIndex) {
      this.reportSubjectObject.checkpoints = this.reportSubjectObject.checkpoints.filter((checkpoint, index) => {
        return index !== groupIndex
      })
    },
    removeAnswer(groupIndex, removeIndex, removeAnswerIndex) {
      this.reportSubjectObject.checkpoints[groupIndex].options[removeIndex].answers = this.reportSubjectObject.checkpoints[groupIndex].options[removeIndex].answers.filter((checkpoint, index) => {
        return index !== removeAnswerIndex
      })
    },
    addAnswer(groupIndex, index) {
      this.reportSubjectObject.checkpoints[groupIndex].options[index].answers.push('')
    },

    selectSubject(option) {
      this.reportSubjectObject.id = option.id
    },
    submit() {
      if (this.reportSubjectObject.id === null) {
        this.$store.dispatch('toast/createToast', {message: 'Please select a subject'})
        return
      }
      if (this.edit) {
        ReportSubject.Update({
          report_id: this.report.id,
          id: this.reportSubjectObject.id,
          requires_educator_comment: this.reportSubjectObject.requires_educator_comment === null ? 0 : this.reportSubjectObject.requires_educator_comment,
          requires_educator_checkpoints: this.reportSubjectObject.requires_educator_checkpoints === null ? 0 : this.reportSubjectObject.requires_educator_checkpoints,
          display_type_id: this.reportSubjectObject.display_type_id,
          educator_display_type_id: this.reportSubjectObject.educator_display_type_id,
          // educator_name: this.reportSubjectObject.educator_name,
          checkpoints: this.reportSubjectObject.checkpoints,
              teacher_id: this.reportSubjectObject.teacher_id,
              report_description: this.reportSubjectObject.report_description,

            }
        ).then(() => {
          this.$buefy.snackbar.open(`Report Subject updated!`)
          this.$emit('close')
        }).catch(err => {
          this.handleError(err)
        })
      } else {
        ReportSubject.Store({

          report_id: this.report.id,
          id: this.reportSubjectObject.id,
          requires_educator_comment: this.reportSubjectObject.requires_educator_comment,
          requires_educator_checkpoints: this.reportSubjectObject.requires_educator_checkpoints,
          display_type_id: this.reportSubjectObject.display_type_id,
          educator_display_type_id: this.reportSubjectObject.educator_display_type_id,
          // educator_name: this.reportSubjectObject.educator_name,
          teacher_id: this.reportSubjectObject.teacher_id,
          checkpoints: this.reportSubjectObject.checkpoints,
          report_description: this.reportSubjectObject.report_description


        }).then(() => {
          this.$buefy.snackbar.open(`Report Subject created!`)
          this.$emit('close')
        }).catch(err => {
          this.handleError(err)
        })
      }
    }
  }
  ,
  mounted() {
    if (this.edit) {
      this.reportSubjectObject = JSON.parse(JSON.stringify(this.reportSubject))
      if (this.reportSubjectObject.report_description_override !==null) {
        this.reportSubjectObject.report_description = this.reportSubjectObject.report_description_override
      }
      this.loaded = true

      // this.loading = true
      // Subject.FetchById(this.reportSubjectObject.id, ['teachers']).then((
      //     this.loading = false
      // ))
    }
    this.loaded = true
  }
  ,
  props: {
    default_year: {
      type: Number,
      default() {
        return new Date().getFullYear()
      }
    },
    manageTerms: {
      type: Boolean,
      default() {
        return false
      }
    },
    terms: {
      type: Array,
      default() {
        return []
      }
    },
    campus_id: {
      type: Number,
      default
          () {
        return 0
      }
    }, term_id: {
      type: Number,
      default
          () {
        return null
      }
    }, parent_id: {
      type: Number,
      default
          () {
        return null
      }
    },
    report:
        {
          type: Object,
          required: true
        }
    , reportSubject:
        {
          type: Object,
          default
              () {
            return {
              name: ''
            }
          }
          ,
        }
    ,
    inModal: {
      type: Boolean,
      default
          () {
        return false
      }
    }
    ,
    edit: {
      type: Boolean,
      default
          () {
        return false
      }
    }
    ,
  }
}
</script>

