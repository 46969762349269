<template>
  <form @submit.prevent="submit">
    <ValidationObserver class="is-relative" ref="observer">

      <div :class="{'modal-card':inModal}" style="width: auto;">
        <header v-if="inModal" class="modal-card-head">
          <p class="modal-card-title">{{ edit === false ? 'Create a new' : 'Edit' }} Report</p>
        </header>
        <section :class="{'modal-card-body':inModal}">
          <b-field expanded label="Name">
            <b-input
                v-model="reportObject.name"
                placeholder="Report name"
                expanded
                required>
            </b-input>
          </b-field>
          <b-field expanded label="Year">
            <b-numberinput expanded required v-model="reportObject.year" min="2020" step="1">

            </b-numberinput>
          </b-field>
          <b-field label="Phase">
            <PhasesFilter @selected="selectPhase"
                          :type="'autocomplete'"
                          :selected_phase_id="reportObject.phase_id"
                          @cleared="reportObject.phase_id=null"
                          :campus_id="reportObject.campus_id"></PhasesFilter>
          </b-field>
          <b-field expanded label="Template">
            <b-select placeholder="Template" expanded required v-model="reportObject.template_id">


              <template v-for="template in $store.state.reports.templates">
                <option :key="template.id" :value="template.id">{{ template.name }}</option>
                <option class="has-text-grey" :key="template.id + 'description'" :value="99" disabled>{{
                    template.description
                  }}
                </option>
              </template>

            </b-select>
          </b-field>
          <b-field label="Has Summary Comment">
            <b-switch :true-value="1" :false-value="0" v-model="reportObject.has_summary_comment">
              {{ reportObject.has_summary_comment === 1 ? 'Yes' : 'No' }}
            </b-switch>

          </b-field>
          <b-field label="Has Mark/Grade Table">
            <b-switch :true-value="1" :false-value="0" v-model="reportObject.has_mark_table">
              {{ reportObject.has_mark_table === 1 ? 'Yes' : 'No' }}
            </b-switch>

          </b-field>
          <b-field label="Is the Final Report for the year">
            <b-switch :true-value="1" :false-value="0" v-model="reportObject.is_final">
              {{ reportObject.is_final === 1 ? 'Yes' : 'No' }}
            </b-switch>

          </b-field>
          <b-field :label="`Select ${$tc('Learner',2)}`">
            <b-switch :true-value="true" :false-value="false" v-model="filter_users">
              {{ filter_users === true ? 'Yes' : 'No' }}
            </b-switch>

          </b-field>
          <b-field v-if="filter_users" label="Filter Direction">
            <b-select v-model="is_blacklist">
              <option :value="0">
                Selected {{ $tc('learner', 2) }} will be included
              </option>
              <option :value="1">
                Selected {{ $tc('learner', 2) }} will be excluded
              </option>
            </b-select>


          </b-field>
          <b-field v-if="filter_users" :label="`${$tc('Learner',1)} Search`">
            <UsersFilter :role="'learner'"
                         :clear-on-select="true"
                         :type="'autocomplete'" @selected="selectUser"
                         :campus_id="reportObject.campus_id"
                         :phase_id="reportObject.phase_id"
            ></UsersFilter>
          </b-field>
          <b-field :label="`${$tc('Learner',2)}`"
                   v-if="filter_users && reportObject.report_user_ids.length>0">
            <b-table
                class="margin-top"
                :data="users"
                :striped="true"
                :hoverable="true"
                :bordered="true"
                :scrollable="true"
            >
              <template #empty>
                <div class="has-text-centered">No {{ $tc('Learner', 2) }}</div>
              </template>
              <b-table-column
                  v-slot="props"
                  label="ID"
                  field="id"
                  sortable
                  width="40"
                  numeric
              >{{ props.row.id }}
              </b-table-column>
              <b-table-column
                  v-slot="props"
                  label="First Name"
                  field="first_name"
                  sortable

              >{{ props.row.first_name }}
              </b-table-column>
              <b-table-column
                  v-slot="props"
                  label="Last Name"
                  field="last_name"
                  sortable

              >{{ props.row.last_name }}
              </b-table-column>


              <b-table-column
                  v-slot="props"
                  label="Actions"
                  custom-key="actions"
                  centered

              >

                <b-button
                    v-if="edit?!!$store.getters['entities/user-permissions/find']('edit reports'):!!$store.getters['entities/user-permissions/find']('create reports')"
                    type="is-primary"
                    @click="startRemoveUser(props.row)" aria-role="listitem">
                  Remove
                </b-button>

              </b-table-column>
            </b-table>

          </b-field>
          <b-field expanded label="School Level">
            <b-select placeholder="School Level" expanded required v-model="reportObject.school_level">
              <option v-for="level in $store.state.reports.school_levels" :value="level.id" :key="level.id">
                {{ level.name }}
              </option>
            </b-select>
          </b-field>
          <b-field expanded label="Start">
            <b-datepicker
                :years-range="[-80,80]" placeholder="Start" required expanded
                v-model="start"></b-datepicker>
          </b-field>
          <b-field expanded label="End">
            <b-datepicker
                :years-range="[-80,80]" placeholder="End" required expanded
                v-model="end"></b-datepicker>
          </b-field>
          <b-field expanded label="Status">
            <b-select expanded required v-model="reportObject.status_id">
              <option v-for="status in $store.state.reports.statuses" :key="status.id" :value="status.id">
                {{ status.name }}
              </option>

            </b-select>

          </b-field>


          <b-field expanded label="Terms">
            <ValidationProvider
                rules="required:min-items:1"
                v-slot="{ errors }"
            >
              <b-dropdown expanded v-model="reportObject.term_ids" multiple aria-role="list">
                <b-button expanded class="select" :class="{'is-empty':reportObject.term_ids.length===0}" slot="trigger">
                  <span>Select Terms</span>

                </b-button>
                <div :key="index" v-for="(group,index) in termsData"
                >
                  <span class="is-size-5 px-2 has-text-weight-bold">{{ group.name }}</span>
                  <b-dropdown-item
                      :key="term.id" v-for="term in group.children" :value="term.id"
                      aria-role="listitem"
                  >
                    <span>{{ term.name }}</span>
                  </b-dropdown-item>
                </div>

              </b-dropdown>
              <span class="has-text-danger">{{ errors[0] }}</span>

            </ValidationProvider>

          </b-field>
          <b-field expanded label="Report Focus Term">

            <b-select placeholder="Select Term" expanded v-model="reportObject.term_id" aria-role="list">
              <optgroup :label="group.name" :key="index" v-for="(group,index) in termsData"
              >
                <option v-for="term in group.children" :key="term.id" :value="term.id">{{ term.name }}</option>

              </optgroup>

            </b-select>


          </b-field>


        </section>
        <footer v-if="inModal" :class="{'modal-card-foot':inModal}">
          <b-button
              :disabled="edit?(!$store.getters['entities/user-permissions/find']('edit reports')||(this.reportObject.status_id === 8 && !this.$store.getters['entities/user-permissions/find']('edit complete reports'))):!$store.getters['entities/user-permissions/find']('create reports')"
              native-type="submit" type="is-primary" :icon-right="$tc(`icons.${edit===false?'create':'edit'}`)">
            Save
          </b-button>
        </footer>
        <div class="pt-3" v-else>
          <b-field position="is-centered" grouped>
            <b-field position="is-centered">
              <b-button
                  :disabled="edit?(!$store.getters['entities/user-permissions/find']('edit reports')||(this.reportObject.status_id === 8 && !this.$store.getters['entities/user-permissions/find']('edit complete reports'))):!$store.getters['entities/user-permissions/find']('create reports')"
                  native-type="submit" :icon-right="$tc(`icons.${edit===false?'create':'edit'}`)" type="is-primary">Save
              </b-button>
            </b-field>
          </b-field>
        </div>
      </div>
      <b-loading v-model="loading" :is-full-page="false" :can-cancel="false"></b-loading>
    </ValidationObserver>
  </form>
</template>

<script>

import {format, isValid} from "date-fns";
import Term from "@/models/Term";
import Report from "@/models/Report";
import {extend, ValidationObserver, ValidationProvider} from "vee-validate";
import {required} from "vee-validate/dist/rules";
import PhasesFilter from "@/components/panelled-dash/PhasesFilter";
import UsersFilter from "@/components/panelled-dash/UsersFilter";
import User from "@/models/User";

extend("required", {
  ...required,
  message: "This field is required",
});

extend("min-items", {
  validate(value, args) {
    return value.length >= args.length;
  },
  params: ["length"],
});
export default {
  name: "ReportForm",
  components: {
    ValidationProvider,
    ValidationObserver,
    PhasesFilter,
    UsersFilter
  },
  data() {
    return {
      loading: false,
      filter_users: false,
      is_blacklist: 1,
      reportObject: {
        name: '',
        assessment_date: format(new Date(), 'yyyy-MM-dd'),
        start: new Date(),
        end: new Date(),
        term_ids: [],
        terms: [],
        campus_id: this.campus_id,
        status_id: 1,
        year: new Date().getFullYear(),
        phase_id: null,
        template_id: null,
        school_level: null,
        term_id: null,
        has_summary_comment: 0,
        is_final: 0,
        has_mark_table: 0,
        report_user_ids: [],
        users: []

      }
    }
  },
  watch: {
    'reportObject.year'(newValue) {
      if (newValue !== null) {
        if (newValue > 2019) {
          this.getTerms()
        }
      }
    }
  },
  computed: {
    users() {
      return User.findIn(this.reportObject.report_user_ids)
    },
    are_users_blacklisted() {
      return User.query().where('report_is_blacklist', 0).exists()
    },
    termsData() {
      if (this.manageTerms) {
        return Term.query()
            .where('campus_id', this.reportObject.campus_id).where('parent_id', null).where(term => {
              return new Date(term.start).getFullYear() === this.reportObject.year
            })
            .with('children').orderBy('start').get()
      }
      return this.terms
    },
    start: {
      get() {
        if (isValid(new Date(this.reportObject.start))) {
          return new Date(this.reportObject.start)
        }
        return null
      }, set(newValue) {
        this.reportObject.start = format(newValue, 'yyyy-MM-dd')
      },
    }, end: {
      get() {
        if (isValid(new Date(this.reportObject.end))) {
          return new Date(this.reportObject.end)
        }
        return null
      }, set(newValue) {
        this.reportObject.end = format(newValue, 'yyyy-MM-dd')
      },
    },
  },
  methods: {
    removeUser(user) {
      this.reportObject.report_user_ids = this.reportObject.report_user_ids.filter(id => {
        return id !== user.id
      })
    },
    startRemoveUser(user) {
      this.$buefy.dialog.confirm({
        title: `Removing ${this.$tc('Learner', 1)}`,
        confirmText: `Remove ${this.$tc('Learner', 1)}`,
        hasIcon: true,
        type: 'is-danger',
        message: `Are you sure you want to remove this ${this.$tc('learner', 1)}?`,
        onConfirm: () => this.removeUser(user)
      })
    },
    selectUser(user) {
      this.reportObject.report_user_ids.push(user.id)
    },
    selectPhase(option) {
      this.reportObject.phase_id = option.id;
    },
    getTerms() {
      this.loading = true
      return Term.FetchAll(
          {
            page: 1,
            limit: 99,
          },
          {year: this.reportObject.year, is_parent: 1, campus_id: this.$store.state.campus.selected_campus_id},
          ["children"]
      ).catch(err => {
        this.handleError(err)
      }).finally(() => {
        this.loading = false
      });
    },

    submit() {
      this.$refs.observer.validate().then((success) => {
        if (!success) {
          this.$store.dispatch('toast/createToast', {message: 'Please select a term'})
          return;
        }
        if (this.reportObject.phase_id === null) {
          this.$store.dispatch('toast/createToast', {message: 'Please select a phase'})
          return;
        }

        if (this.edit) {
          Report.Update({
                name: this.reportObject.name,
                year: this.reportObject.year,
                start: this.reportObject.start,
                end: this.reportObject.end,
                terms: this.reportObject.term_ids,
                campus_id: this.reportObject.campus_id,
                status_id: this.reportObject.status_id,
                id: this.reportObject.id,
                template_id: this.reportObject.template_id,
                phase_id: this.reportObject.phase_id,
                school_level: this.reportObject.school_level,
                term_id: this.reportObject.term_id,
                has_summary_comment: this.reportObject.has_summary_comment,
                has_mark_table: this.reportObject.has_mark_table,
                is_final: this.reportObject.is_final,
                users: this.filter_users ? this.reportObject.report_user_ids.map(id => {
                  return {user_id: id, is_blacklist: this.is_blacklist}
                }) : []
              }
          ).then(() => {
            Report.FetchById(this.report.id, ['users', 'terms', 'phase']).then(() => {
              this.$buefy.snackbar.open(`Report updated!`)
              this.$emit('close')
            })

          }).catch(err => {
            this.handleError(err)
          })
        } else {
          Report.Store({

            name: this.reportObject.name,
            year: this.reportObject.year,
            start: this.reportObject.start,
            end: this.reportObject.end,
            terms: this.reportObject.term_ids,
            campus_id: this.reportObject.campus_id,
            status_id: this.reportObject.status_id, template_id: this.reportObject.template_id,
            phase_id: this.reportObject.phase_id,
            school_level: this.reportObject.school_level,
            term_id: this.reportObject.term_id,
            has_summary_comment: this.reportObject.has_summary_comment,
            has_mark_table: this.reportObject.has_mark_table,
            is_final: this.reportObject.is_final,
            users: this.filter_users ? this.reportObject.report_user_ids.map(id => {
              return {user_id: id, is_blacklist: this.is_blacklist}
            }) : []


          }).then(({entities: {reports}}) => {
            Report.FetchById(reports[0].id, ['users', 'terms', 'phase']).then(() => {
              this.$buefy.snackbar.open(`Report created!`)
              this.$emit('created', reports[0].id)
              this.$emit('close')
            })

          }).catch(err => {
            this.handleError(err)
          })
        }
      })
    }
  }
  ,
  mounted() {
    if (this.edit) {
      this.loading = true
      Report.FetchById(this.report.id, ['users', 'terms', 'phase']).then(() => {
        this.reportObject = JSON.parse(JSON.stringify(Report.query().whereId(this.report.id).withAll().first()))
        if (this.reportObject.users.length > 0) {
          this.filter_users = true
          if (this.are_users_blacklisted) {
            this.is_blacklist = 0

          }
        }
      }).catch(err => {
        console.log(err)
        this.handleError(err)
      }).finally(() => {
        this.loading = false
      })

    } else {
      this.reportObject.year = this.default_year
      this.start = new Date()
      this.end = new Date()
    }
    this.getTerms()
  }
  ,
  props: {
    default_year: {
      type: Number,
      default() {
        return new Date().getFullYear()
      }
    },
    manageTerms: {
      type: Boolean,
      default() {
        return false
      }
    },
    terms: {
      type: Array,
      default() {
        return []
      }
    },
    campus_id: {
      type: Number,
      default
          () {
        return 0
      }
    }, term_id: {
      type: Number,
      default
          () {
        return null
      }
    }, parent_id: {
      type: Number,
      default
          () {
        return null
      }
    },
    report:
        {
          type: Object,
          default
              () {
            return {
              name: ''
            }
          }
          ,
        }
    ,
    inModal: {
      type: Boolean,
      default
          () {
        return false
      }
    }
    ,
    edit: {
      type: Boolean,
      default
          () {
        return false
      }
    }
    ,
  }
}
</script>

